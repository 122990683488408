import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";

let env = process.env.NODE_ENV;

var devConfig = {
  apiKey: "AIzaSyCutejyQ0HkSojr-toaernT_5Etl48YvmM",
  authDomain: "club-argos.firebaseapp.com",
  projectId: "club-argos",
  storageBucket: "club-argos.appspot.com",
  messagingSenderId: "962254795438",
  appId: "1:962254795438:web:5375be6e72d0830112733f",
  measurementId: "G-JHHRVLYW7X"
};

var prodConfig = {
  apiKey: "AIzaSyCutejyQ0HkSojr-toaernT_5Etl48YvmM",
  authDomain: "club-argos.firebaseapp.com",
  projectId: "club-argos",
  storageBucket: "club-argos.appspot.com",
  messagingSenderId: "962254795438",
  appId: "1:962254795438:web:5375be6e72d0830112733f",
  measurementId: "G-JHHRVLYW7X"
};

firebase.initializeApp(env == "production" ? prodConfig : devConfig);

// if (env == "development") {
// firebase.functions().useFunctionsEmulator("http://localhost:5001");
// }

firebase.auth().languageCode = "es";

export const db = firebase.firestore();
export const fb = firebase;
