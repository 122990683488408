import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import es from 'vuetify/lib/locale/es';
import 'vuetify/dist/vuetify.min.css';
Vue.use(Vuetify);


export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#c6d600",
        secondary: "#5170b5",
        accent: "#c6d600",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
      dark: {
        primary: "#c6d600",
        secondary: "#5170b5",
        accent: "#c6d600",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
  lang: {
    current: "es",
  },
  icons: {
    iconfont: "fa",
  },
});
