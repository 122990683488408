<template>
  <v-app>
    <Snackbar class="Snack" />
    <v-container v-if="appbar" style="z-index: 2;">

      <div class="parent">
        <div class="div1"> <img src="@/assets/logoArgos.svg" class="logoArgos"> </div>
        <div class="div2">

          <v-menu style="border-radius: 20px;" class="btn-back" rounded transition="scale-transition" offset-y>
            <template v-slot:activator="{ on, attrs }">

              <div class="user-client" v-bind="attrs" v-on="on">
                <span> <v-icon color="#fff" small class="mr-2">fa fa-user</v-icon> {{ nameUser[0] }} {{ nameUser[1]
                }}</span>

              </div>
            </template>
            <v-card style="border-radius: 20px;">
              <v-card-text>
                <v-list-item-content class="justify-center">
                  <div class="mx-auto text-center">
                    <v-avatar color="primary">
                      <v-avatar color="#171643" size="30">
                        <span class="white--text text-h7">{{ firstChart }}</span>
                      </v-avatar>
                    </v-avatar>
                    <h3>{{ user.name }}</h3>
                    <p class="text-caption mt-1">
                      {{ user.email }}
                    </p>

                    <v-btn depressed rounded color="#171643" dark @click="logOut">
                      Salir
                    </v-btn>
                  </div>
                </v-list-item-content>
              </v-card-text>
            </v-card>
          </v-menu>

          <div class="ticket-wrap" @click="toPage('/couponClaimed')">
            <v-icon small color="#fff" class="mr-2">fa fa-ticket</v-icon> Cupones
          </div>


        </div>
        <transition name="slide-fade">
          <input class="div3 d-none-mobile" v-if="searchShow" placeholder="🔍 Buscar..." v-model="search" type="text">
        </transition>
        <transition name="slide-fade">
          <v-btn class="div4" fab color="#121136" small link to="/coupons" v-if="btnBack"> <v-icon small
              color="#fff">fa-solid fa-arrow-left</v-icon></v-btn>
        </transition>
      </div>

    </v-container>

    <v-main style="z-index: 1;">
      <transition-page>
        <router-view />
      </transition-page>
    </v-main>
    <footerVue  style="position: relative; margin-top: 20px;"/>
    <background />
  </v-app>
</template>

<script>
import footerVue from "@/components/footer";
import Snackbar from "@/components/snackbar";
import background from "@/components/background";
import TransitionPage from "@/components/TransitionPage";
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";

export default {
  name: "App",
  components: {
    Snackbar,
    background,
    TransitionPage,
    footerVue,
 
  },
  data: () => ({
    menu: null,
    appbar: false,
    nameUser: [],
    claimedCoupons: [],
    searchShow: false,

    //
  }),
  computed: {
    ...mapState(["role", "selectedBusiness", "search", "user", "city",
      "country",]),
    firstChart() {
      if (this.nameUser.length > 0) {
        return `${this.nameUser[0].charAt(0)}.${this.nameUser[1].charAt(0)}`;
      } else {
        return "";
      }
    },
    amountCoupons() {
      return this.claimedCoupons.length;
    },
    search: {
      get: function () {
        return this.$store.state.search;
      },
      set: function (newValue) {
        this.$store.commit("setSearch", newValue);
      },
    },
  },
  methods: {
    ...mapActions(["setUser", "Alert_", "_country",
      "_city",]),
    logOut() {
      if (this.user) {
        fb.auth()
          .signOut()
          .then(() => {
            this.setUser(false);
            this.$router.push({
              path: "/",
            });
          });
      }
    },
    getHome() {
      this.$router.push("/coupons");
    },
    toPage(to) {
      this.$router.push(to);
    },
  },
  watch: {
    $route(e) {
      this.appbar = e.meta.appbar;
      this.searchShow = e.meta.searchShow;
      this.btnBack = e.meta.btnBack;
      var rutes = ["coupons", "couponClaimed"];
      if (rutes.includes(this.$route.name) && !this.user) {
        this.$router.push("/");
      }
    },
  },
  mounted() {
    fb.auth().onAuthStateChanged(async (authUser) => {
      let router = this.$router.currentRoute;
      this.appbar = router.meta.appbar;
      this.searchShow = router.meta.searchShow;
      this.btnBack = router.meta.btnBack;

      if (authUser) {
        var currentUser = await fb.auth().currentUser.getIdTokenResult();
        await db
          .collection("users")
          .doc(currentUser.claims.user_id)
          .onSnapshot((user) => {
            user = user.data();
            user[`.key`] = currentUser.claims.user_id;
            //console.debug(currentUser.claims.user_id)

            this.setUser(user);

            this.$binding(
              "claimedCoupons",
              db
                .collection("claimedCoupons")
                .where("userId", "==", user[".key"])
                .where("status", "==", "pending")
                .orderBy("claimedDate", "desc")
            );

            this.UserApp = user;
            this.nameUser = user.name.split(" ");
            if (!user.status == "pending") {
              this.Alert_({
                text: "Este perfíl de usuario no tiene permitido ingresar a la plataforma",
                timeout: 2000,
                btn_closed: true,
                icon: false,
                iconType: "mdi-check",
                type: "error",
              });
              this.logOut();
            } else {
              if (this.$router.currentRoute.name == "login") {
                this.$router.push({ path: "/coupons" });
              } else if (
                this.$router.currentRoute.meta.allowedRoles &&
                !this.$router.currentRoute.meta.allowedRoles.includes(user.type)
              ) {
                this.$router.push({ path: "/coupons" });
              }
            }
          });
      } else {
        this.setUser(false);
        //console.debug(this.$route.name);
        var rutes = ["coupons", "couponClaimed"];
        if (rutes.includes(this.$route.name) && !this.user) {
          this.$router.push("/");
        }
      }
    });
  },
};
</script>
<style lang="scss" scoped>
@import "@/_responsive.scss";

.name-user {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 1em;
  font-weight: 600;
  line-height: 1em;
  color: #171643;
}

.v-toolbar__content {
  width: 100% !important;
  display: flex;

  background-color: #171643 !important;
  justify-content: space-between;
  align-items: center;
}

.none-mobile {
  display: block;
}

.none-mobile {
  @include responsive(mobile) {
    display: none;
  }
}

.Snack {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.parent {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  z-index: 10;
}

.div1 {
  grid-area: 1 / 1 / 2 / 3;

  .logoArgos {
    width: 80%;

    @include responsive(mobile) {
      width: 160%;

    }
  }
}

.div2 {
  grid-area: 1 / 9 / 2 / 13;
  background-color: #c6d600;
  height: 40px;
  border-radius: 30px;
  z-index: 10;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  z-index: 10;
  overflow: hidden;
  // filter: drop-shadow(0px 2px 5px #0800221e);

  @include responsive(mobile) {
    grid-area: 1 / 5 / 2 / 13;

  }
}

.div4 {
  grid-area: 1 / 3 / 3 / 10;

  @include responsive(mobile) {
    transform: translateX(20px);
    position: absolute;
    top: 70px;
    left: 0px;
  }
}

.div3 {
  grid-area: 1 / 3 / 3 / 10;
  background-color: #171643;
  // filter: drop-shadow(0px 3px 2px #08002221);
  height: 40px;
  color: #fff;
  border-radius: 30px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 800;
  padding-left: 30px;
  z-index: 9;
  outline: none;

  &:focus {
    border: none;
  }

  &:active {
    border: none;
  }
}

.ticket-wrap {
  border-left: #ffffff 1px solid;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  font-weight: 800;
  cursor: pointer;

  @include responsive(mobile) {
    font-size: 14px;

  }

  &:hover {
    background-color: #acb804;
  }

}

.user-client {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  font-weight: 800;

  @include responsive(mobile) {
    font-size: 12px;

  }

  &:hover {
    background-color: #acb804;
  }

}

.d-none-mobile {
  display: block;

  @include responsive(mobile) {
    display: none !important;

  }
}

.btn-back {}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 1s ease;
}

.slide-fade-leave-active {
  transition: all .2s ease-in-out;
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(200px);
  opacity: 0;
}</style>