import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: login,
    meta: {
      appbar: false,
      searchShow: false,
      btnBack: false,
      index: 1,
      transitionName: 'fade',
      background: false,
    }
  },
  {
    path: '/coupons',
    name: 'coupons',
    meta: {
      appbar: true,
      searchShow: true,
      btnBack: false,
      index: 1,
      transitionName: 'zoomIn-SlideOut',
      background: false,
    },

    component: () => import('../views/homeBussines.vue')
  },
  {
    path: '/couponClaimed',
    name: 'couponClaimed',
    meta: {
      appbar: true,
      searchShow: false,
      btnBack: true,
      index: 1,
      transitionName: 'zoomIn-SlideOut',
      background: false,
    },
    component: () => import('../views/couponClaimed.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  
  routes
})

export default router
