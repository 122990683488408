<template>
  <div class="login">

    <div class="parent-main">
      <div class="div1"> <img src="@/assets/logoArgos.svg" class="logoArgos-login"></div>
      <div class="div2"> <img width="100px" v-for="(i, index) in categories" :key="index" :src="i" /></div>
    </div>
    <img src="@/assets/logos-2-07.svg" class="img-logoo" width="300px" />
    <img src="@/assets/logos-2-07.svg" width="150px" class="my-12 mobile-show" />
    <div class="wrap-login-main">
      <v-card max-width="370" class="wrap-login" :loading="btnloading" elevation="0">
        <v-card-title class="desktop-show">
          <img src="@/assets/logos-2-08.svg" height="70" />
        </v-card-title>
        <v-card-subtitle v-if="otpDialog" class="mt-2">
          Se ha enviado un SMS al número +{{ code.callingCodes[0]
          }}{{ phone }} incluyendo un código numérico de 6 digítos al número de
          celular ingresado.
          <a @click="reset" style="color: #071e49; cursor: pointer">Cambiar número</a>
        </v-card-subtitle>
        <h3 class="mb-4 mt-4" v-else style="text-align: center; color: #01102b; white-space: wrap">
          Para ingresar, coloca <br />tu
          número de celular
        </h3>
        <v-card-text>


          <otp :auth="authObject" v-if="otpDialog" ref="code" @cancel="(otpDialog = false), (loading = false)"
            @success="otpConfirmed" />
          <inputPhone v-else @retunValue="valueInput" @actions="onNext" />
        </v-card-text>
        <v-card-actions class="flex-colunm">
          <v-btn color="primary" class="mb-4 px-12 black--text" height="50" width="100%" v-if="otpDialog"
            style="border-radius: 10px" :loading="loading" block :disabled="counter > 0" @click="onNext">
            Reenviar código <span v-if="counter > 0">({{ counter }})</span>
          </v-btn>

          <v-btn color="primary" class="mb-4 px-12 black--text" height="50" width="100%" v-else
            style="border-radius: 10px" :loading="loading" block @click="onNext">
            Enviar código
          </v-btn>
          <v-btn text block dark color="primary" class="mt-2" @click="dialogBePartOfClub = true">
            Quiero ser parte del club
          </v-btn>
        </v-card-actions>
      </v-card>
      <img src="@/assets/logoArgos.svg" class="mt-12 mobile-show" width="100px">

    </div>


    <v-dialog v-model="dialogBePartOfClub" v-if="dialogBePartOfClub" width="600" persistent>
      <v-card style="border-radius: 15px">
        <v-card-title>
          Se parte del club
          <v-btn elevation="0" right absolute fab small @click="dialogBePartOfClub = false">
            <v-icon> fa fa-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" lg="6" md="6">
              <v-img @click="setType('client')" class="" style="border-radius: 10px" src="@/assets/client-01.svg">
              </v-img>
            </v-col>
            <v-col cols="12" sm="6" lg="6" md="6">
              <v-img @click="setType('business')" class="" style="border-radius: 10px" src="@/assets/client-02.svg">
              </v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog persistent scrollable v-model="businessDialog" width="800" v-if="businessDialog">
      <formBusiness @close="businessDialog = false" />
    </v-dialog>
    <v-dialog persistent scrollable v-model="clientDialog" width="400" v-if="clientDialog">
      <formValidationClient @close="clientDialog = false" />
    </v-dialog>

  </div>
</template>
<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import formBusiness from "@/components/businessFormValidations.vue";
import formValidationClient from "@/components/formValidationClient.vue";
import inputPhone from "@/components/inputPhone.vue";
import alertMessage from "@/mixins/alertMixin";
import otp from "@/components/insertCode.vue";
export default {
  name: "Login",
  components: {
    formBusiness,
    formValidationClient,
    inputPhone,
    otp,
  },
  mixins: [alertMessage],
  data() {
    return {
      businessDialog: false,
      clientDialog: false,
      dialogBePartOfClub: false,
      valid: true,
      loading: false,
      dni: null,
      otpDialog: false,
      authObject: null,
      verificationId: null,
      counter: 0,
      phone: null,
      code: null,
      btnloading: false,
      categories: [
        require('@/assets/categories/categoria-06.svg'),
        require('@/assets/categories/categoria-07.svg'),
        require('@/assets/categories/categoria-08.svg'),
        require('@/assets/categories/categoria-09.svg'),
        require('@/assets/categories/categoria-10.svg'),
        require('@/assets/categories/categoria-11.svg'),
        require('@/assets/categories/categoria-12.svg'),
        require('@/assets/categories/categoria-13.svg'),
        require('@/assets/categories/categoria-14.svg'),
        require('@/assets/categories/categoria-15.svg'),
      ],
      rules: {
        required: (value) => !!value || "Obligatorio",
        number: (value) =>
          /^\d*[0-9](|.\d*[0-9]|,\d*[0-9])?$/.test(value) || "Solo numeros",
        min: (v) => (v && v.length >= 13) || "Mínimo 13 caracteres",
      },
    };
  },
  methods: {
    ...mapActions(["Alert_"]),
    setType(type) {
      console.log(type);
      this.dialogBePartOfClub = false;



      if (type == "business") {
        this.businessDialog = true;

      }
      if (type == "client") {
        this.clientDialog = true;

      }
    },
    alertMessage(type, message) {
      this.Alert_({
        text: message,
        timeout: 2000,
        btn_closed: true,
        icon: false,
        iconType: "mdi-check",
        type: type,
      });
    },
    // isNumber: function (evt) {
    //   evt = evt ? evt : window.event;
    //   var charCode = evt.which ? evt.which : evt.keyCode;
    //   if (
    //     charCode > 31 &&
    //    (charCode < 48 || charCode > 57) &&
    //     (charCode >= 96 || charCode <= 105) &&
    //     charCode !== 46 &&
    //     charCode !== 110
    //   ) {
    //     evt.preventDefault();
    //   } else {
    //     return true;
    //   }
    // },
    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9','Backspace'];
      const keyPressed = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },
    otpConfirmed(otp) {
      // this.otpDialog = false;

      var phone = `${this.code.callingCodes[0]}${this.phone}`;

      var datos = {
        phone: phone,
        otp: otp,
      };
      var usersCreateCustomToken = fb
        .functions()
        .httpsCallable("usersCreateCustomToken");
      usersCreateCustomToken(datos)
        .then((response) => {
          this.singInToken(response.data.customToken);
        })
        .catch((error) => {
          this.alertMessage("error", error.message);
          this.$refs.code.loading = false;
          this.$refs.code.otp = "";
        });
    },
    valueInput(e, code) {
      this.phone = e;
      this.code = code;
    },

    success(e) {
      this.userDialog = false;
      this.alertMessage("success", "Usuario registrado exitosamente.");
    }, onNext() {
      this.loading = true;
      if (this.phone != null) {
        this.mfaLogin();
      } else {
        this.loading = false;
        this.alertMessage("error", "😬 Ingrese el campo obligatorio");
      }
    },
    mfaLogin() {
      var phone = `${this.code.callingCodes[0]}${this.phone}`;
      var datos = {
        phone: phone,
      };

      var usersSendOtp = fb
        .functions()
        .httpsCallable("usersSendOtp");

      usersSendOtp(datos).then(() => {
        this.otpDialog = true;
        this.loading = false;
        this.counter = 60;

        let timer = setInterval(() => {
          this.counter--;

          if (this.counter == 0) {
            clearInterval(timer);
          }
        }, 1000);
      }).catch(error => {
        this.alertMessage("error", error.message);
        this.loading = false;
      })
    },
    singInToken(token) {
      fb.auth()
        .signInWithCustomToken(token)
        .then(async (userCredential) => {
          console.debug("userCredential", userCredential);

          this.$emit("closed");
          this.$emit("NextOrder");
          // setTimeout(() => {
          //   this.loading = false;
          //   this.$router.push("/store");
          // }, 1000);
          // ...
        })
        .catch((error) => {
          this.alertMessage("error", error.message);
          this.loading = false;

          // //console.debug(error);
        });
    },
    reset() {
      this.phone = "";
      this.otpDialog = false;
    },

    // onNext() {
    //   this.btnloading = true;
    //   if (this.dni != null) {
    //     var usersCreateCustomToken = fb
    //       .functions()
    //       .httpsCallable("usersCreateCustomToken");
    //     var data = {
    //       dni: this.dni.replace("-", ""),
    //     };
    //     usersCreateCustomToken(data)
    //       .then(async (response) => {
    //         //console.debug(response);
    //         await fb.auth().signInWithCustomToken(response.data.customToken);
    //       })
    //       .catch((error) => {
    //         this.alertMessage("error", error.message);
    //         this.dni = null;
    //         this.btnloading = false;
    //       });
    //   } else {
    //     this.btnloading = false;
    //     this.alertMessage("error", "Llena los campos seleccionados 😬");
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
@import "@/_responsive.scss";

.login {
  height: 100vh;
  width: 100vw;
  background-color: transparent;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: space-evenly;
  align-items: center;


  @include responsive(mobile) {
    flex-direction: column;
    justify-content: flex-start;

    .img-logoo {
      width: 150px !important;
      margin-top: 50px;
      margin-bottom: 50px;

      @include responsive(mobile) {
        display: none;
      }

    }
  }

  .wrap-login-main {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;

    @include responsive(mobile) {
      margin-top: 0px !important;


    }


    .wrap-login {
      max-width: 450px;
      // background-color: #212031 !important;
      border-radius: 15px !important;
      display: flex;
      // filter: drop-shadow(0px 10px 20px #01102b81);
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      padding: 0px 20px;

    }
  }
}

.btns {
  width: 100%;
}

.flex-colunm {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.wrap-gategories {
  margin-left: 300px;
  background-color: #01102b;
  height: 100px;
  width: 80%;
  position: absolute;
  border-radius: 20px;
  top: 20px;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  // img{
  //   width: 7% !important;
  // }

}


.parent-main {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  position: absolute;
  top: 10px;

  @include responsive(mobile) {
    display: none !important;
  }
}

.div1 {
  grid-area: 1 / 1 / 2 / 3;
}

.div2 {
  grid-area: 1 / 3 / 2 / 8;
  background-color: #01102b;
  border-radius: 20px;


  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  img {
    width: 10%;
    height: 100px;
    object-fit: contain;

  }
}

.logoArgos-login {
  height: 100px;
}

.desktop-show {
  display: block;

  @include responsive(mobile) {
    display: none !important;
  }
}

.mobile-show {
  display: none;

  @include responsive(mobile) {
    display: block !important;
  }
}
</style>
